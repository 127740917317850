.user-info-container {
    display: flex;
    flex-wrap: wrap;
    width: auto;
}
.user-info {
    flex: 1 1 50%;
    box-sizing: border-box;
    padding: 5px 0 0 30px;
    text-align: start;
}

.user-password-age-alert, .user-account-disabled-alert, .user-mfa-factors-alert {
    color: red;
    margin: 0 20% 10px 20%;
}

.alert-link, .alert-link:visited {
    color: red;
    text-decoration: underline;
    cursor: pointer;
}

.alert-link:hover {
    color:firebrick;
    text-decoration: underline;
}

.alert-title {
    font-size: 24px;
    font-weight: bold;    
}

.alert-text {
    font-size: 16px;
    margin: 0 0 10px 0;
}

.bold {
    font-weight: bold;
}
