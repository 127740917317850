.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 50px 0 20px;
    /*background-color: #f8f8f8;*/
    border-bottom: 1px solid #ddd;
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    height: 100%;
}

.username {
    font-size: 1rem;
    font-family: Roboto, sans-serif;
    color: #333;
}
