.auth-code-inputs {
    display: flex;
    justify-content: space-between;
    max-width: 300px;
    margin: 0 auto;
    gap: 5px;
}

.auth-code-input {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 24px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

p {
    margin: 0;
}
