.dropdown {
    margin-right: 10px; /* Space between input and button */
    padding: 10px;
    font-size: 16px;
    width: 300px;
}

select option[disabled] {
    color: gray;
}

.highlight-link {
    color: blue;
    font-weight: bold;
    text-decoration: underline;
}
