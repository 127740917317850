.title-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner-container {
    display: flex;
    justify-content: center;
}

.spinner {
    margin-left: 10px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #000;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
