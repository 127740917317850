/* Specific styles for the input form */
.form-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-div input {
    margin-right: 10px; /* Space between input and button */
    padding: 10px;
    font-size: 16px;
    width: 400px;
}

input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
}
