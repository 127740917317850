.login-status-bar {
    display: flex;
    /*flex-direction: row;*/
    font-family: Roboto, sans-serif;
    font-size: 10px;
    justify-content: end;
    align-items: end;
    height: 20px;
    /*background-color: #f8f9fa;*/
}
