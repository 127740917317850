.main-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;    /* Center the children horizontally */
    font-family: Roboto, sans-serif;
    box-sizing: border-box; /* Include padding and border in element's total width and height */
    /*background-color: #f0f0f0;*/
    /*border: 1px solid #ddd;*/
    margin-top: 120px;
}

.child-div {
    flex: 1; /* Allow child <div>s to grow and shrink based on their content */
    /*width: 100%;*/
    width: 80%;
    margin: 0 0 5px 0;
    padding: 10px 0;
    /*background-color: #f0f0f0;*/
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /*border: 1px solid #ddd;*/
    text-align: center; /* Center text within each div */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.app-div-subtitle {
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 10px 0;
}

.flashing-text {
    font-weight: bold;
    color: red;
    animation: flash 1s infinite;
}

@keyframes flash {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.app-text {
    font-size: 16px;
    color: black;
    margin: 10px 0 10px 0;
}

.app-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 50px;
    font-weight: bold;
    background-color: #007bff;
    color: #fff;
}

.button-under {
    margin: 10px;
}

.button-right {
    margin: 0 20px;
}

button:hover {
    background-color: #0056b3;
}

.button-valid {
    opacity: 1;
}

.button-invalid {
    opacity: 0.2;
    cursor: not-allowed;
}

.button-invalid:hover {
    background-color: #007bff;
}

.active-step {
    border: 8px solid #333;
}

.app-error {
    color: red;
    /*font-size: 14px;*/
}

.highlight-link {
    background: none;
    border: none;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    font: inherit;
}

button.highlight-link:hover {
    background-color: white;
}

.disabled-link {
    pointer-events: none;
    cursor: not-allowed;
    color: #ccc;
}
